.sql-query-container {
  margin: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

h1 {
  margin-bottom: 20px;
}

.chat-window {
  width: 100%;
  max-width: 600px;
  height: 400px;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.chat-bubble {
  max-width: 80%;
  padding: 10px;
  margin: 5px 0;
  border-radius: 10px;
  white-space: pre-wrap;
}

.user-message {
  align-self: flex-end;
  background-color: #e0f7fa;
  text-align: right;
}

.assistant-message {
  align-self: flex-start;
  background-color: #f1f1f1;
  text-align: left;
}

.input-form {
  display: flex;
  width: 100%;
  max-width: 600px;
  margin-top: 10px;
}

input {
  flex: 1;
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-right: 10px;
}

button {
  padding: 10px;
  font-size: 16px;
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.error {
  color: red;
  margin-top: 10px;
}
