body {
  margin: 0;
  font-family: Arial, sans-serif;
}

#root {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
